import React, { useCallback, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Col,
  Row,
} from "reactstrap";
// Utils
import {
  isAuthenticated,
  userHasAccess,
  userRoles,
  getUserDisplayName,
} from "../utils";
// Styling

const NavigationBar = React.memo((props) => {
  const [isOpen, setIsOpen] = useState(false);
  const isAuthFlag = isAuthenticated();

  const toggle = () => setIsOpen(!isOpen);

  const getAuthRoutes = useCallback(
    () =>
      isAuthFlag ? (
        <NavItem>
          <NavLink tag={Link} to="/logout">
            Logout ({getUserDisplayName()})
          </NavLink>
        </NavItem>
      ) : (
        <>
          <NavItem>
            <NavLink tag={Link} to="/wizard">
              Inscriere
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/login">
              Login
            </NavLink>
          </NavItem>
        </>
      ),
    [isAuthFlag]
  );

  const getManagingRoutes = useCallback(() => {
    if (!isAuthFlag) return null;

    const isAdmin = userHasAccess([userRoles.ADMIN]);
    const isEmployee = userHasAccess([userRoles.EMPLOYEE]);
    const isLegitimat = userHasAccess([userRoles.LEGITIMAT]);

    if (isLegitimat) {
      return (
        <>
          <NavItem>
            <NavLink tag={Link} to="/user-whatsapp-groups">
              Whatsapp
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/user-subscriptions">
              Inrolari
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/profile">
              Profil
            </NavLink>
          </NavItem>
        </>
      );
    }

    if (isEmployee) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Administrare
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag={Link} to="/change-password">
              Schimbare parola
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }

    if (isAdmin) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Administrare
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag={Link} to="/locations">
              Locatii
            </DropdownItem>
            <DropdownItem tag={Link} to="/subjects">
              Discipline
            </DropdownItem>
            <DropdownItem tag={Link} to="/courses">
              Cursuri
            </DropdownItem>
            <DropdownItem tag={Link} to="/whatsapp-groups">
              Grupuri de Whatsapp
            </DropdownItem>
            <DropdownItem tag={Link} to="/users-management">
              Utilizatori
            </DropdownItem>
            <DropdownItem tag={Link} to="/register">
              Inroleaza utilizator
            </DropdownItem>
            <DropdownItem tag={Link} to="/application-configs">
              Configuratii aplicatie
            </DropdownItem>
            <DropdownItem tag={Link} to="/change-password">
              Schimbare parola
            </DropdownItem>
            {/* <DropdownItem tag={Link} to="/logging">
            Loguri
          </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  }, [isAuthFlag]);

  const getGeneralRoutes = useCallback(() => {
    if (!isAuthFlag) return null;

    const isAdmin = userHasAccess([userRoles.ADMIN]);

    return (
      <>
        <NavItem>
          <NavLink tag={Link} to="/">
            Acasa
          </NavLink>
        </NavItem>
        {isAdmin ? (
          <></>
        ) : // TODO: hide for the moment, maybe activate it
        // <NavItem>
        //   <NavLink tag={Link} to="/files">
        //     Fisiere
        //   </NavLink>
        // </NavItem>
        null}
      </>
    );
  }, [isAuthFlag]);

  return (
    <div>
      <Navbar color="primary" dark expand="md">
        <NavbarBrand tag={Link} to="/"></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="d-flex w-100" navbar>
            <Row className="justify-content-between w-100">
              <Col className="d-inline-flex justify-content-start">
                {getGeneralRoutes()}
                {getManagingRoutes()}
              </Col>
              <Col className="d-inline-flex justify-content-end">
                {getAuthRoutes()}
              </Col>
            </Row>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
});

export default withRouter(NavigationBar);
