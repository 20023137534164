// Packages
import React, { useMemo, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Table, Spinner, Button, Input, InputGroup } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { BsPencilFill, BsCheckLg, BsXLg, BsDownload, BsSearch } from "react-icons/bs";
// Utils
import {
  userHasAccess,
  userRoles,
  catchHandler,
  getRequestHeaders,
  logsConfigsTypeToLabel
} from "../utils";
// Components
import Pagination from "../components/Pagination"
// Styling
import "./Logging.css";

const Logging = React.memo((props) => {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const [searchValue, setSearchValue] = useState('');

  const getData = useCallback((currentPage, searchValue) => {
    setLoading(true);

    axios
      .get(`/api/logging/list-logs`, getRequestHeaders(Object.assign({
        page: currentPage
      }, searchValue && {
        search: searchValue
      })))
      .then((res) => {
        const { data = {} } = res;
        const { items = [], totalCount = 0 } = data;
        setLogs(items);
        setTotalCount(totalCount);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);

  const getRows = useCallback(
    () =>
      logs.length ? logs.map(
        ({ user, method, action, createdAt }, index) => (
          <tr key={`logs-row-${index}`}>
            <td>{user.email}</td>
            <td>{method}</td>
            <td>{action}</td>
            <td>{(new Date(createdAt)).toLocaleString()}</td>
          </tr>
        )
      ) : null,
    [logs]
  );

  const onSearchChange = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setSearchValue(() => value);
  }, []);

  const handleSearch = useCallback((event) => {
    const { type, key } = event;

    if(type === 'click' || (type === 'keyup' && key === 'Enter')) {
      getData(1, searchValue)
    }
  }, [searchValue]);

  return (
    <Container className="mt-5 mb-5" style={{ maxWidth: "1400px" }}>
      <Row className="mt-5">
        <Col className="d-flex justify-content-between align-items-center">
          <span className="">
            <h3>Loguri ({totalCount})</h3>
          </span>
        </Col>
      </Row>
      <Row className="mt-1 justify-content-end">
        <Col className="col-3 d-inline-flex">
          <InputGroup>
            <Input
              type="text"
              placeholder="Search..."
              onChange={onSearchChange}
              onKeyUp={handleSearch}
              value={searchValue}
            />
            <Button
              disabled={loading}
              color="secondary"
              size="sm"
              onClick={handleSearch}
            >
              <BsSearch />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Table hover>
            <thead>
              <tr>
                <th>User</th>
                <th>Request</th>
                <th>Actiune</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={4} className="text-center">
                    <Spinner color="dark" style={{ width: "3rem", height: "3rem" }} />
                  </td>
                </tr>
              ) : (
                getRows()
              )}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            onSetPage={setCurrentPage}
          />
        </Col>
      </Row>
    </Container>
  );
})

export default Logging;
