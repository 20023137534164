import React from "react";
import { FormGroup, Label, Input, Row, Col, Container, FormFeedback } from "reactstrap";

class FirstStep extends React.Component {
  state = {
    checked: false,
    error: false,
  };

  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.scrollToElement = this.scrollToElement.bind(this);
    this.checkboxRef = React.createRef();
  }

  // Important for Wizard
  isValidated() {
    const { checked } = this.state;
    if (checked) {
      return true;
    } else {
      this.setState({ error: true });
      this.scrollToElement();
      return false;
    }
  }

  handleChange(event) {
    const { target = {} } = event;
    const { checked = false } = target;
    this.setState({ checked, error: false });
  }

  scrollToElement() {
    if (this.checkboxRef.current) {
      this.checkboxRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  render() {
    const { checked, error } = this.state;

    return (
      <Row
        style={{
          maxWidth: "100%",
          maxHeight: "calc(100vh - 400px)",
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
          overflowX: "hidden",
          overflowY: "auto",
          margin: 0,
        }}
      >
        <Col md={12} className="text-center">
          <h3>Acord GDPR</h3>
        </Col>
        <Col md={12} className="mt-3">
          <Container>
            <p>
              Având în vedere prevederile Regulamentului UE nr. 697/2016 privind
              protecția persoanelor fizice în ceea ce privește prelucrarea
              datelor cu caracter personal şi privind libera circulație a
              acestor date şi de abrogare a Directivei 95/46/CE, îmi exprim în
              mod expres consimțământul ca Aeroclubul României sa îmi colecteze,
              prelucreze și stocheze datele cu caracter personal în cadrul
              furnizării serviciilor și îndeplinirii activităților pe care le
              solicit și cu privire la care emit prezenta declarație pe proprie
              răspundere.
            </p>
            <p>
              De asemenea, iau la cunoștință si sunt de acord cu faptul că
              datele cu caracter personal pe care le comunic Aeroclubului
              României vor fi colectate, prelucrate si stocate în scopul
              furnizării serviciilor și îndeplinirii activităților pe care le
              solicit și cu privire la care emit prezenta declarație pe proprie
              răspundere, inclusiv în vederea emiterii documentelor de
              licențiere și autorizare solicitate, conform prevederilor legale.
            </p>
            <p>
              De asemenea, accept că prelucrarea datelor cu caracter personal de
              către Aeroclubul României are în vedere următoarele:
            </p>
            <ul>
              <li>
                Furnizarea serviciilor si desfășurarea activităților solicitate,
                emiterea documentelor de licențiere și autorizare
              </li>
              <li>
                Datele sunt stocate pe întreaga durata a perioadei în care sunt
                păstrate în arhiva Aeroclubului României documentele întocmite
                în vederea și pe parcursul furnizării serviciilor și a
                desfășurării activităților solicitate
              </li>
              <li>
                Datele cu caracter personal nu sunt divulgate către terți, cu
                excepția situațiilor prevăzute de lege
              </li>
            </ul>
            <p>
              Subsemnatul înțeleg și accept că Aeroclubul României prelucrează
              următoarele tipuri de date cu caracter personal:
            </p>
            <ul>
              <li>
                Date de baza de identificare: de exemplu nume, prenume, cod
                numeric personal, funcție etc.
              </li>
              <li>
                Date de contact, precum adresa, număr de telefon, adresa de
                e-mail, etc.
              </li>
              <li>Cont bancar și alte informații financiare</li>
              <li>
                Date colectate cu ocazia înregistrării la Aeroclubul României,
                cu ocazia furnizării serviciilor solicitate și desfășurării
                activităților solicitate, cu privire la care emit prezenta
                declarație pe proprie răspundere
              </li>
              <li>
                Orice tip de date ce provin în urma derulării de raporturi
                juridice de orice natura cu Aeroclubul României
              </li>
              <li>Date IT de identificare</li>
            </ul>
          </Container>
        </Col>
        <Col md={12} className="text-center mt-3">
          <FormGroup check inline>
            <Input
              innerRef={this.checkboxRef}
              id="accept"
              name="accept"
              type="checkbox"
              checked={checked}
              invalid={error}
              onChange={this.handleChange}
            />
            <Label for="accept" check>
              Accept <strong>Termenii si conditiile</strong>
            </Label>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default FirstStep;
