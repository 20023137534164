// Packages
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Button,
  Input,
  InputGroup,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { BsPencilFill, BsTrashFill, BsSearch } from "react-icons/bs";
// Utils
import { catchHandler, getRequestHeaders } from "../utils";
// Components
import Pagination from "../components/Pagination";
// Styling
import "./WhatsappGroups.css";

const WhatsappGroups = React.memo((props) => {
  const { state: locationState = {} } = useLocation();
  const { pageNumber = 1 } = locationState;
  const [loading, setLoading] = useState(false);
  const [loadingActions, setLoadingActions] = useState(false);
  const [whatsappGroups, setWhatsappGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const [searchValue, setSearchValue] = useState("");
  const [searchTriggerValue, setSearchTriggerValue] = useState("");

  const getData = useCallback((currentPage, searchValue) => {
    setLoading(true);

    axios
      .get(
        `/api/whatsapp-groups/list-whatsapp-groups`,
        getRequestHeaders(
          Object.assign(
            {
              page: currentPage,
            },
            searchValue && {
              search: searchValue,
            }
          )
        )
      )
      .then((res) => {
        const { data = {} } = res;
        const { items = [], totalCount = 0 } = data;
        setWhatsappGroups(items);
        setTotalCount(totalCount);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getData(currentPage, searchTriggerValue);
  }, [currentPage, searchTriggerValue]);

  const onAddClick = useCallback(() => {
    history.push(`${history.location.pathname}/add`);
  }, [history]);

  const onEditClick = useCallback(
    (id) => {
      history.push(`${history.location.pathname}/edit/${id}`);
    },
    [history]
  );

  const onRemoveClick = useCallback(
    (id) => {
      setLoadingActions(true);

      axios
        .delete(
          `/api/whatsapp-groups/remove-whatsapp-group/${id}`,
          {},
          getRequestHeaders()
        )
        .then(() => {
          toast.success("Grupul de Whatsapp a fost sters cu succes");
          getData(currentPage, searchTriggerValue);
        })
        .catch(catchHandlerBinded)
        .finally(() => {
          setLoadingActions(false);
        });
    },
    [currentPage, searchTriggerValue]
  );

  const getRows = useCallback(
    () =>
      whatsappGroups.length ? (
        whatsappGroups.map(({ _id, link, location, subject }) => (
          <tr key={`whatsapp-group-row-${_id}`}>
            <td>{link}</td>
            <td>{location?.name}</td>
            <td>{subject?.name}</td>
            <td className="text-center">
              <Button
                disabled={loadingActions}
                color="warning"
                size="sm"
                onClick={onEditClick.bind(null, _id)}
              >
                <BsPencilFill />
              </Button>
            </td>
            <td className="text-center">
              <Button
                disabled={loadingActions}
                color="danger"
                size="sm"
                onClick={onRemoveClick.bind(null, _id)}
              >
                <BsTrashFill />
              </Button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={5} className="text-center">
            <span>Fara rezultat</span>
          </td>
        </tr>
      ),
    [onEditClick, onRemoveClick, whatsappGroups, loadingActions]
  );

  const onSearchChange = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setSearchValue(() => value);
  }, []);

  const handleSearch = useCallback(
    (event) => {
      const { type, key } = event;

      if (type === "click" || (type === "keyup" && key === "Enter")) {
        setSearchTriggerValue(searchValue);
        setCurrentPage(1);
      }
    },
    [searchValue]
  );

  return (
    <Container className="mt-5 mb-5" style={{ maxWidth: "1400px" }}>
      <Row className="mt-5">
        <Col className="d-flex justify-content-between align-items-center">
          <span className="">
            <h3>Administrare grupuri de Whatsapp ({totalCount})</h3>
          </span>
          <span>
            <Button
              disabled={loading}
              className=""
              color="primary"
              size="sm"
              onClick={onAddClick}
            >
              Adaugare grup de Whatsapp
            </Button>
          </span>
        </Col>
      </Row>
      <Row className="mt-1 justify-content-end">
        <Col className="col-3 d-inline-flex">
          <InputGroup>
            <Input
              type="text"
              placeholder="Search..."
              onChange={onSearchChange}
              onKeyUp={handleSearch}
              value={searchValue}
            />
            <Button
              disabled={loadingActions || loading}
              color="secondary"
              size="sm"
              onClick={handleSearch}
            >
              <BsSearch />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Table hover>
            <thead>
              <tr>
                <th>Link</th>
                <th>Locatie</th>
                <th>Disciplina</th>
                <th className="text-center">Modificare</th>
                <th className="text-center">Stergere</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5} className="text-center">
                    <Spinner
                      color="dark"
                      style={{ width: "3rem", height: "3rem" }}
                    />
                  </td>
                </tr>
              ) : (
                getRows()
              )}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            onSetPage={setCurrentPage}
          />
        </Col>
      </Row>
    </Container>
  );
});

export default WhatsappGroups;
