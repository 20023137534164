// Packages
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Spinner } from "reactstrap";
import axios from "axios";
// Pages
// auth
import Login from "./pages/Login";
import Wizard from "./pages/Wizard";
import ForgotPassword from "./pages/ForgotPassword";
import ActivateUser from "./pages/ActivateUser";
import ResetPassword from "./pages/ResetPassword";
import RegistrationComplete from "./pages/RegistrationComplete";
import Logout from "./pages/Logout";
// managing
import Profile from "./pages/Profile";
import ChangePassword from "./pages/ChangePassword";
import Locations from "./pages/Locations";
import AddLocation from "./pages/AddLocation";
import EditLocation from "./pages/EditLocation";
import Subjects from "./pages/Subjects";
import AddSubject from "./pages/AddSubject";
import EditSubject from "./pages/EditSubject";
import Courses from "./pages/Courses";
import AddCourse from "./pages/AddCourse";
import EditCourse from "./pages/EditCourse";
import WhatsappGroups from "./pages/WhatsappGroups";
import AddWhatsappGroup from "./pages/AddWhatsappGroup";
import EditWhatsappGroup from "./pages/EditWhatsappGroup";
import UsersManagement from "./pages/UsersManagement";
import EditUser from "./pages/EditUser";
import Subscriptions from "./pages/Subscriptions";
import Register from "./pages/Register";
// general
import Receipts from "./pages/Receipts";
import PaymentFailed from "./pages/PaymentFailed";
import PaymentSuccess from "./pages/PaymentSuccess";
// home
import Landing from "./pages/Landing";
import Dashboard from "./pages/Dashboard";
// not found
import NotFound from "./pages/NotFound";
// hidden
import Files from "./pages/Files";
import Logging from "./pages/Logging";
// Components
import NavigationBar from "./components/NavigationBar";
import ExclusivePublicRoute from "./components/ExclusivePublicRoute";
import FallbackRoute from "./components/FallbackRoute";
import PrivateRoute from "./components/PrivateRoute";
// Utils
import {
  catchHandler,
  getCsrfToken,
  headers,
  setAudienceHeader,
  userRoles,
  setCsrfHeaders,
} from "./utils";
// Styling
import "./App.css";
import UserWhatsappGroups from "./pages/UserWhatsappGroups";
import UserSubscriptions from "./pages/UserSubscriptions";
import AddUserSubscription from "./pages/AddUserSubscription";
import ApplicationConfigs from "./pages/ApplicationConfigs";

const App = React.memo((props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    axios.interceptors.response.use(null, (error) => {
      const { config, response } = error;
      if (
        config &&
        response &&
        response.status === 403 &&
        response.data &&
        response.data.ctk
      ) {
        return getCsrfToken().then((token) => {
          config.headers[headers.csrf] = token;
          return axios.request(config);
        });
      }

      return Promise.reject(error);
    });
    setAudienceHeader();
    getCsrfToken()
      .then((token) => setCsrfHeaders(token))
      .catch(catchHandler)
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="app-container">
      <NavigationBar />
      {loading ? (
        <Spinner
          color="dark"
          style={{
            position: "absolute",
            top: "calc(50% - 3rem)",
            left: "calc(50% - 3rem)",
            width: "6rem",
            height: "6rem",
          }}
        />
      ) : (
        <Switch>
          {/* AUTH ROUTES */}
          <ExclusivePublicRoute
            exact
            path="/login"
            component={Login}
            pageTitle="Login"
          />
          <ExclusivePublicRoute
            exact
            path="/wizard"
            component={Wizard}
            pageTitle="Inscriere"
          />
          <ExclusivePublicRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
            pageTitle="Parola Uitata"
          />
          <ExclusivePublicRoute
            exact
            path="/activate-user/:token"
            component={ActivateUser}
            pageTitle="Activare Utilizator"
          />
          <ExclusivePublicRoute
            exact
            path="/reset-password/:token"
            component={ResetPassword}
            pageTitle="Resetare Parola"
          />
          <ExclusivePublicRoute
            exact
            path="/registration-complete"
            component={RegistrationComplete}
            pageTitle="Inregistrare finalizata"
          />
          <PrivateRoute
            exact
            path="/logout"
            component={Logout}
            pageTitle="Logout"
          />
          {/* MANAGING ROUTES */}
          <PrivateRoute
            exact
            path="/change-password"
            component={ChangePassword}
            pageTitle="Schimbare Parola"
          />
          <PrivateRoute
            exact
            path="/locations"
            component={Locations}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Administrare Locatii"
          />
          <PrivateRoute
            exact
            path="/locations/add"
            component={AddLocation}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Adaugare Locatie"
          />
          <PrivateRoute
            exact
            path="/locations/edit/:id"
            component={EditLocation}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Modificare Locatie"
          />
          <PrivateRoute
            exact
            path="/subjects"
            component={Subjects}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Administrare Discipline"
          />
          <PrivateRoute
            exact
            path="/subjects/add"
            component={AddSubject}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Adaugare Disciplina"
          />
          <PrivateRoute
            exact
            path="/subjects/edit/:id"
            component={EditSubject}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Modificare Disciplina"
          />
          <PrivateRoute
            exact
            path="/courses"
            component={Courses}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Administrare Cursuri"
          />
          <PrivateRoute
            exact
            path="/courses/add"
            component={AddCourse}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Adaugare Curs"
          />
          <PrivateRoute
            exact
            path="/courses/edit/:id"
            component={EditCourse}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Modificare Curs"
          />
          <PrivateRoute
            exact
            path="/whatsapp-groups"
            component={WhatsappGroups}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Administrare grupuri de Whatsapp"
          />
          <PrivateRoute
            exact
            path="/whatsapp-groups/add"
            component={AddWhatsappGroup}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Adaugare grup de Whatsapp"
          />
          <PrivateRoute
            exact
            path="/whatsapp-groups/edit/:id"
            component={EditWhatsappGroup}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Modificare grup de Whatsapp"
          />
          <PrivateRoute
            exact
            path="/users-management"
            component={UsersManagement}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Administrare Utilizatori"
          />
          <PrivateRoute
            exact
            path="/users-management/edit/:id"
            component={EditUser}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Modificare Utilizator"
          />
          <PrivateRoute
            exact
            path="/users-management/subscriptions/:id"
            component={Subscriptions}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Inrolari"
          />
          <PrivateRoute
            exact
            path="/register"
            component={Register}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Inrolare utilizator"
          />
          <PrivateRoute
            exact
            path="/application-configs"
            component={ApplicationConfigs}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Configuratii aplicatie"
          />
          {/* GENERAL ROUTES */}
          <PrivateRoute
            exact
            path="/profile"
            accessRoles={[userRoles.LEGITIMAT]}
            component={Profile}
            pageTitle="Administrare Profil"
          />
          <PrivateRoute
            exact
            path="/user-whatsapp-groups"
            component={UserWhatsappGroups}
            accessRoles={[userRoles.LEGITIMAT]}
            pageTitle="Grupuri de Whatsapp"
          />
          <PrivateRoute
            exact
            path="/user-subscriptions"
            component={UserSubscriptions}
            accessRoles={[userRoles.LEGITIMAT]}
            pageTitle="Grupuri de Whatsapp"
          />
          <PrivateRoute
            exact
            path="/user-subscriptions/add"
            component={AddUserSubscription}
            accessRoles={[userRoles.LEGITIMAT]}
            pageTitle="Grupuri de Whatsapp"
          />
          <PrivateRoute
            exact
            path="/payment-failed"
            component={PaymentFailed}
            pageTitle="Plata esuata"
          />
          <PrivateRoute
            exact
            path="/payment-success"
            component={PaymentSuccess}
            pageTitle="Plata cu succes"
          />
          {/* HOME ROUTE */}
          <FallbackRoute
            exact
            path="/"
            accessRoles={[userRoles.ADMIN, userRoles.EMPLOYEE]}
            main={Dashboard}
            second={Receipts}
            fallback={Landing}
            mainTitle="Dashboard"
            secondTitle="Plati"
            fallbackTitle=""
          />
          {/* HIDDEN */}
          <PrivateRoute
            exact
            path="/files"
            component={Files}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Fisiere"
          />
          <PrivateRoute
            exact
            path="/logging"
            component={Logging}
            accessRoles={[userRoles.ADMIN]}
            pageTitle="Loguri"
          />
          {/* NOT FOUND ROUTE */}
          <Route path="*" component={NotFound} />
        </Switch>
      )}
      <img id="site-logo" src="/logo-header2.png" />
    </div>
  );
});

export default App;
