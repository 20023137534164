// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DatePicker } from "reactstrap-date-picker";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import {
  MONTH_NAMES,
  catchHandler,
  getRequestHeaders,
  getSelectStyles,
  oneYearIncrease,
} from "../utils";
// Styling
import "./ApplicationConfigs.css";

const ApplicationConfigs = React.memo((props) => {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    currentPaymentName: "",
    nextPaymentName: "",
    currentPaymentPrice: 0, // EUR
    nextPaymentPrice: 0, // EUR
    currentNotarialAgreementLimitDate: "",
    nextNotarialAgreementLimitDate: "",
    currentSubventionatLimitDate: "",
    nextSubventionatLimitDate: "",
    currentTaxResetDate: "",
    nextTaxResetDate: "",
    yearSufixFlag: false,
    annualIncrementFlag: false,
    activeMembruInLot: false,
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    setLoading(true);

    axios
      .get("/api/application-configs/get-configs", getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        setFields(data);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .patch(
          "/api/application-configs/edit-configs",
          fields,
          getRequestHeaders()
        )
        .then(() => {
          toast.success("Configuratiile au fost actualizate cu success");
        })
        .catch((err) => {
          const {
            currentPaymentName,
            nextPaymentName,
            currentPaymentPrice,
            nextPaymentPrice,
            currentNotarialAgreementLimitDate,
            nextNotarialAgreementLimitDate,
            currentSubventionatLimitDate,
            nextSubventionatLimitDate,
            currentTaxResetDate,
            nextTaxResetDate,
            yearSufixFlag,
            annualIncrementFlag,
          } = catchHandler(history, err);
          setErrors({
            currentPaymentName,
            nextPaymentName,
            currentPaymentPrice,
            nextPaymentPrice,
            currentNotarialAgreementLimitDate,
            nextNotarialAgreementLimitDate,
            currentSubventionatLimitDate,
            nextSubventionatLimitDate,
            currentTaxResetDate,
            nextTaxResetDate,
            yearSufixFlag,
            annualIncrementFlag,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [history, fields]
  );

  const onToggleActive = useCallback((event) => {
    event.preventDefault();

    setLoading(true);
    setErrors({});

    axios
      .patch(
        "/api/application-configs/toggle-membru-in-lot",
        {},
        getRequestHeaders()
      )
      .then(() => {
        toast.success("Membru in Lot a fost schimbat cu success");
        setFields((prevFields) => ({
          activeMembruInLot: !prevFields.activeMembruInLot
        }));
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, value } = target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  const onCheckChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, checked } = target;
    setFields((prevFields) => ({ ...prevFields, [name]: checked }));
  }, []);

  const onDateChange = useCallback((name, value) => {
    // Ensure the input is a valid date
    if (!value) return;

    // Set the time to the end of the day
    const endOfDay = new Date(value);
    endOfDay.setHours(23, 59, 59, 999);

    setFields((prevFields) => ({
      ...prevFields,
      [name]: endOfDay.toISOString(),
    }));
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">
              Modificare configuratii aplicatie
            </h2>
            <Form>
              <FormGroup>
                <Label for="currentPaymentName">Denumirea platii</Label>
                {fields["yearSufixFlag"] && (
                  <h6>
                    Exemplu rezultat: {fields["currentPaymentName"]?.trim()}{" "}
                    {currentYear}
                  </h6>
                )}
                <Input
                  id="currentPaymentName"
                  name="currentPaymentName"
                  type="text"
                  invalid={Boolean(errors["currentPaymentName"])}
                  value={fields["currentPaymentName"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["currentPaymentName"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="nextPaymentName">
                  Denumirea platii dupa resetare
                </Label>
                {fields["yearSufixFlag"] && (
                  <h6>
                    Exemplu rezultat: {fields["nextPaymentName"]?.trim()}{" "}
                    {Number(currentYear) + 1}
                  </h6>
                )}
                <Input
                  id="nextPaymentName"
                  name="nextPaymentName"
                  type="text"
                  invalid={Boolean(errors["nextPaymentName"])}
                  value={fields["nextPaymentName"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["nextPaymentName"]}</FormFeedback>
              </FormGroup>
              <FormGroup check>
                <Input
                  id="yearSufixFlag"
                  name="yearSufixFlag"
                  type="checkbox"
                  checked={fields["yearSufixFlag"]}
                  onChange={onCheckChange}
                />
                <Label for="yearSufixFlag" check>
                  Includere an curent ca sufix in denumirea platii (ex.: "Taxa
                  legitimare" + sufix an = "Taxa legitimare {currentYear}")
                </Label>
                <h6>
                  Anul curent va fi stabilit in momentul resetarii platii. (ex.:
                  daca resetarea a fost setata pe data de 01.05.{currentYear},
                  atunci, incepand cu data 01.05.{currentYear} pana la urmatoare
                  resetare, aceasta va include anul "{currentYear}"" in sufixul
                  denumirii, chiar daca s-a trecut intr-un nou an calendaristic)
                </h6>
              </FormGroup>
              <FormGroup>
                <Label for="currentPaymentPrice">Pretul taxei (EUR)</Label>
                <Input
                  id="currentPaymentPrice"
                  name="currentPaymentPrice"
                  type="number"
                  min={0}
                  invalid={Boolean(errors["currentPaymentPrice"])}
                  value={fields["currentPaymentPrice"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["currentPaymentPrice"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="nextPaymentPrice">
                  Pretul taxei dupa resetare (EUR)
                </Label>
                <Input
                  id="nextPaymentPrice"
                  name="nextPaymentPrice"
                  type="number"
                  min={0}
                  invalid={Boolean(errors["nextPaymentPrice"])}
                  value={fields["nextPaymentPrice"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["nextPaymentPrice"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="mb-3 styled-date-picker-container">
                <Label for="currentNotarialAgreementLimitDate">
                  Data limita pentru <strong>acord notarial</strong> (final de
                  zi)
                </Label>
                <div
                  className={
                    Boolean(errors["currentNotarialAgreementLimitDate"])
                      ? "is-invalid"
                      : ""
                  }
                >
                  <DatePicker
                    id="currentNotarialAgreementLimitDate"
                    className={
                      Boolean(errors["currentNotarialAgreementLimitDate"])
                        ? "is-invalid"
                        : ""
                    }
                    styles={getSelectStyles(
                      Boolean(errors["currentNotarialAgreementLimitDate"])
                    )}
                    name="currentNotarialAgreementLimitDate"
                    dateFormat="DD/MM/YYYY"
                    placeholder="DD/MM/YYYY"
                    monthLabels={MONTH_NAMES}
                    minDate={new Date().toISOString()}
                    value={fields["currentNotarialAgreementLimitDate"]}
                    onChange={onDateChange.bind(
                      null,
                      "currentNotarialAgreementLimitDate"
                    )}
                    pickMonthElement={"default"}
                    autoComplete="off"
                  />
                </div>
                <FormFeedback>
                  {errors["currentNotarialAgreementLimitDate"]}
                </FormFeedback>
              </FormGroup>
              <FormGroup className="mb-3 styled-date-picker-container">
                <Label for="nextNotarialAgreementLimitDate">
                  Data limita urmatoare pentru <strong>acord notarial</strong>{" "}
                  (final de zi)
                </Label>
                <div
                  className={
                    Boolean(errors["nextNotarialAgreementLimitDate"])
                      ? "is-invalid"
                      : ""
                  }
                >
                  <DatePicker
                    id="nextNotarialAgreementLimitDate"
                    className={
                      Boolean(errors["nextNotarialAgreementLimitDate"])
                        ? "is-invalid"
                        : ""
                    }
                    styles={getSelectStyles(
                      Boolean(errors["nextNotarialAgreementLimitDate"])
                    )}
                    name="nextNotarialAgreementLimitDate"
                    dateFormat="DD/MM/YYYY"
                    placeholder="DD/MM/YYYY"
                    monthLabels={MONTH_NAMES}
                    minDate={new Date().toISOString()}
                    value={
                      Boolean(fields["annualIncrementFlag"])
                        ? oneYearIncrease(
                            fields["currentNotarialAgreementLimitDate"]
                          )
                        : fields["nextNotarialAgreementLimitDate"]
                    }
                    onChange={onDateChange.bind(
                      null,
                      "nextNotarialAgreementLimitDate"
                    )}
                    pickMonthElement={"default"}
                    autoComplete="off"
                    disabled={Boolean(fields["annualIncrementFlag"])}
                  />
                </div>
                <FormFeedback>{errors["nextTaxResetDate"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="mb-3 styled-date-picker-container">
                <Label for="currentSubventionatLimitDate">
                  Data limita pentru <strong>inscrierea subventionata</strong>{" "}
                  (final de zi)
                </Label>
                <div
                  className={
                    Boolean(errors["currentSubventionatLimitDate"])
                      ? "is-invalid"
                      : ""
                  }
                >
                  <DatePicker
                    id="currentSubventionatLimitDate"
                    className={
                      Boolean(errors["currentSubventionatLimitDate"])
                        ? "is-invalid"
                        : ""
                    }
                    styles={getSelectStyles(
                      Boolean(errors["currentSubventionatLimitDate"])
                    )}
                    name="currentSubventionatLimitDate"
                    dateFormat="DD/MM/YYYY"
                    placeholder="DD/MM/YYYY"
                    monthLabels={MONTH_NAMES}
                    minDate={new Date().toISOString()}
                    value={fields["currentSubventionatLimitDate"]}
                    onChange={onDateChange.bind(
                      null,
                      "currentSubventionatLimitDate"
                    )}
                    pickMonthElement={"default"}
                    autoComplete="off"
                  />
                </div>
                <FormFeedback>
                  {errors["currentSubventionatLimitDate"]}
                </FormFeedback>
              </FormGroup>
              <FormGroup className="mb-3 styled-date-picker-container">
                <Label for="nextSubventionatLimitDate">
                  Data limita urmatoare pentru{" "}
                  <strong>inscrierea subventionata</strong> (final de zi)
                </Label>
                <div
                  className={
                    Boolean(errors["nextSubventionatLimitDate"])
                      ? "is-invalid"
                      : ""
                  }
                >
                  <DatePicker
                    id="nextSubventionatLimitDate"
                    className={
                      Boolean(errors["nextSubventionatLimitDate"])
                        ? "is-invalid"
                        : ""
                    }
                    styles={getSelectStyles(
                      Boolean(errors["nextSubventionatLimitDate"])
                    )}
                    name="nextSubventionatLimitDate"
                    dateFormat="DD/MM/YYYY"
                    placeholder="DD/MM/YYYY"
                    monthLabels={MONTH_NAMES}
                    minDate={new Date().toISOString()}
                    value={
                      Boolean(fields["annualIncrementFlag"])
                        ? oneYearIncrease(
                            fields["currentSubventionatLimitDate"]
                          )
                        : fields["nextSubventionatLimitDate"]
                    }
                    onChange={onDateChange.bind(
                      null,
                      "nextSubventionatLimitDate"
                    )}
                    pickMonthElement={"default"}
                    autoComplete="off"
                    disabled={Boolean(fields["annualIncrementFlag"])}
                  />
                </div>
                <FormFeedback>{errors["nextTaxResetDate"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="mb-3 styled-date-picker-container">
                <Label for="currentTaxResetDate">
                  Data resetare <strong>taxa si blocare cursuri neaprobate</strong> (final de zi)
                </Label>
                <div
                  className={
                    Boolean(errors["currentTaxResetDate"]) ? "is-invalid" : ""
                  }
                >
                  <DatePicker
                    id="currentTaxResetDate"
                    className={
                      Boolean(errors["currentTaxResetDate"]) ? "is-invalid" : ""
                    }
                    styles={getSelectStyles(
                      Boolean(errors["currentTaxResetDate"])
                    )}
                    name="currentTaxResetDate"
                    dateFormat="DD/MM/YYYY"
                    placeholder="DD/MM/YYYY"
                    monthLabels={MONTH_NAMES}
                    minDate={new Date().toISOString()}
                    value={fields["currentTaxResetDate"]}
                    onChange={onDateChange.bind(null, "currentTaxResetDate")}
                    pickMonthElement={"default"}
                    autoComplete="off"
                  />
                </div>
                <FormFeedback>{errors["currentTaxResetDate"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="mb-3 styled-date-picker-container">
                <Label for="nextTaxResetDate">
                  Data urmatoare pentru resetare <strong>taxa si blocare cursuri neaprobate</strong> (final de
                  zi)
                </Label>
                <div
                  className={
                    Boolean(errors["nextTaxResetDate"]) ? "is-invalid" : ""
                  }
                >
                  <DatePicker
                    id="nextTaxResetDate"
                    className={
                      Boolean(errors["nextTaxResetDate"]) ? "is-invalid" : ""
                    }
                    styles={getSelectStyles(
                      Boolean(errors["nextTaxResetDate"])
                    )}
                    name="nextTaxResetDate"
                    dateFormat="DD/MM/YYYY"
                    placeholder="DD/MM/YYYY"
                    monthLabels={MONTH_NAMES}
                    minDate={new Date().toISOString()}
                    value={
                      Boolean(fields["annualIncrementFlag"])
                        ? oneYearIncrease(fields["currentTaxResetDate"])
                        : fields["nextTaxResetDate"]
                    }
                    onChange={onDateChange.bind(null, "nextTaxResetDate")}
                    pickMonthElement={"default"}
                    autoComplete="off"
                    disabled={Boolean(fields["annualIncrementFlag"])}
                  />
                </div>
                <FormFeedback>{errors["nextTaxResetDate"]}</FormFeedback>
              </FormGroup>
              <FormGroup check>
                <Input
                  id="annualIncrementFlag"
                  name="annualIncrementFlag"
                  type="checkbox"
                  checked={fields["annualIncrementFlag"]}
                  onChange={onCheckChange}
                />
                <Label for="annualIncrementFlag" check>
                  Auto-incrementare anuala
                </Label>
                <h6>
                  Toate setarile ce au in componenta lor o data asociata vor fi
                  incrementate cu <strong>1an</strong> in momentul resetarii
                  taxei.
                </h6>
              </FormGroup>
              <br />
              <h6>
                <p>
                  ATENTIE! In cazul in care optiunea de <u>auto-incrementare</u>{" "}
                  nu este bifata, noile valori pentru <strong>Denumire</strong>,{" "}
                  <strong>Pret</strong>, <strong>Acord notarial</strong>,{" "}
                  <strong>Inscriere subventionata</strong> si{" "}
                  <strong>Resetare taxa</strong> vor trebui setate din nou
                  manual, in momentul in care acestea sunt resetate impreuna cu
                  taxa. In caz contrar, acestea isi vor pastra valorile
                  anterioare.
                </p>
              </h6>
              <FormGroup className="d-flex justify-content-between mt-5">
                <Button
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Salvare
                </Button>
                {fields.activeMembruInLot ? (
                  <Button color="warning" onClick={onToggleActive}>
                    Dezactivare Membru in Lot
                  </Button>
                ) : (
                  <Button color="success" onClick={onToggleActive}>
                    Activare Membru in Lot
                  </Button>
                )}
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default ApplicationConfigs;
