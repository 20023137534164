import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { logout } from ".";

export default function catchHandler(history, err) {
  const [swapedHistory, swapedErr] =
    history && !err ? [err, history] : [history, err];
  const { response = {}, toastOptions } = swapedErr;
  const { data = {} } = response;
  const { msg, authenticate, ctk, login } = data;

  if (msg && !ctk) {
    if (login) {
      toast.error(
        <div>
          <p>{msg}</p>
          <Link to="/login">Login aici</Link>
        </div>,
        toastOptions
      );
    } else {
      toast.error(msg, toastOptions);
    }
  }

  if (swapedHistory && authenticate === true) {
    logout();
    swapedHistory && swapedHistory.push("/login");
  }

  return data;
}
