// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import {
  AGE_LIMITS,
  calculateAge,
  catchHandler,
  entriesToOptions,
  getRequestHeaders,
  getUserBirthDate,
  getUserForeignCitizen,
} from "../utils";
// Styling
import "./AddUserSubscription.css";
import {
  collaborationTypes,
  collaborationTypesLabels,
} from "../utils/constants";

const AddUserSubscription = React.memo((props) => {
  const rootPathname = useMemo(() => "/user-subscriptions", []);
  const [locationsOptions, setLocationsOptions] = useState([]);
  const [subjectsOptions, setSubjectsOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [fields, setFields] = useState({
    collaborationType: "",
    location: "",
    subject: "",
    course: "",
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const unavailableSubvetionatOption = useMemo(() => {
    const foreignCitizen = getUserForeignCitizen();
    const birthDate = getUserBirthDate();
    const [years, months] = calculateAge(birthDate);
    const ageInMonths = years * 12 + months;

    return foreignCitizen || ageInMonths > AGE_LIMITS.OVER;
  }, []);
  const [disabledSubvetionatOption, setDisabledSubvetionatOption] =
    useState(true);
  const collaborationTypesOptions = useMemo(
    () =>
      [
        {
          value: collaborationTypes.CONTRACT,
          label: `${collaborationTypesLabels[collaborationTypes.CONTRACT]} (contra cost)`,
        },
        !unavailableSubvetionatOption && {
          value: collaborationTypes.SUBVENTIONAT,
          label: collaborationTypesLabels[collaborationTypes.SUBVENTIONAT],
          isDisabled: disabledSubvetionatOption,
        },
      ].filter(Boolean),
    [disabledSubvetionatOption]
  );

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/wizard/select-locations`, getRequestHeaders())
      .then((res) => {
        const { data = [] } = res;
        setLocationsOptions(data.map(entriesToOptions));
      })
      .catch(catchHandler)
      .finally(() => {
        setLoading(false);
      });

    axios
      .get(`/api/wizard/get-subventionat`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { limit } = data;
        const disabledSubventionat = Date.now() > new Date(limit).getTime();
        setDisabledSubvetionatOption(disabledSubventionat);
      })
      .catch(catchHandler);
  }, []);

  const onCollaborationTypeChange = useCallback((option) => {
    const { value = "" } = option || {};
    onLocationsSelectChange(value, null);
  }, []);

  const onLocationsSelectChange = useCallback((collaborationType, option) => {
    const { value = "" } = option || {};

    if (!value || !collaborationType) {
      setSubjectsOptions([]);
    } else {
      setLoading(true);

      axios
        .get(
          `/api/wizard/select-subjects/${value}?subventionat=${collaborationType === collaborationTypes.SUBVENTIONAT}`,
          getRequestHeaders()
        )
        .then((res) => {
          const { data = [] } = res;
          setSubjectsOptions(
            data.map((entry) =>
              entriesToOptions(entry, { extra: "deactivatedForSubventionat" })
            )
          );
        })
        .catch(catchHandlerBinded)
        .finally(() => {
          setLoading(false);
        });
    }

    setFields((prevFields) => ({
      ...prevFields,
      collaborationType,
      location: value,
      subject: "",
      course: "",
    }));
    setCourseOptions([]);
    setDisabledSubmit(true);
  }, []);

  const onSubjectsSelectChange = useCallback((option) => {
    const { value = "" } = option || {};

    if (!value) {
      setCourseOptions([]);
    } else {
      setLoading(true);

      axios
        .get(`/api/wizard/select-courses/${value}`, getRequestHeaders())
        .then((res) => {
          const { data = [] } = res;
          setCourseOptions(data.map(entriesToOptions));
        })
        .catch(catchHandlerBinded)
        .finally(() => {
          setLoading(false);
        });
    }

    setFields((prevFields) => ({ ...prevFields, subject: value, course: "" }));
    setDisabledSubmit(true);
  }, []);

  const onCoursesSelectChange = useCallback((option) => {
    const { value = "" } = option || {};
    setFields((prevFields) => ({ ...prevFields, course: value }));
    setDisabledSubmit(!value);
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .post(
          "/api/user-subscriptions/create-subscription",
          fields,
          getRequestHeaders()
        )
        .then(() => {
          toast.success(`Inrolarea a fost adaugata cu succes`);
          history.push(rootPathname);
        })
        .catch((err) => {
          const {
            location = "",
            subject = "",
            course = "",
          } = catchHandler(history, err);
          setErrors({ location, subject, course });
          setLoading(false);
        });
    },
    [history, fields, rootPathname]
  );

  const onCancel = useCallback(
    (event) => {
      event.preventDefault();
      history.push(rootPathname);
    },
    [history, rootPathname]
  );

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">Adaugare Inrolare</h2>
            <Form>
              <FormGroup className="mt-3">
                <Select
                  name="collaborationType"
                  placeholder="Tip colaborare"
                  noOptionsMessage={() => <span>Fara rezultat</span>}
                  isClearable={true}
                  onChange={onCollaborationTypeChange}
                  value={
                    collaborationTypesOptions.find(
                      ({ value }) => value === fields["collaborationType"]
                    ) || null
                  }
                  options={collaborationTypesOptions}
                />
                <FormFeedback>{errors["location"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="mt-3">
                <Select
                  name="location"
                  placeholder="Locatie"
                  noOptionsMessage={() =>
                    !fields["collaborationType"] ? (
                      <span>Selectati un tip de colaborare</span>
                    ) : (
                      <span>Fara rezultat</span>
                    )
                  }
                  isClearable={true}
                  onChange={onLocationsSelectChange.bind(null, fields["collaborationType"])}
                  value={
                    locationsOptions.find(
                      ({ value }) => value === fields["location"]
                    ) || null
                  }
                  options={fields["collaborationType"] ? locationsOptions : []}
                />
                <FormFeedback>{errors["location"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Select
                  name="subject"
                  placeholder="Disciplina"
                  noOptionsMessage={() =>
                    !fields["location"] ? (
                      <span>Selectati o locatie</span>
                    ) : (
                      <span>Fara rezultat</span>
                    )
                  }
                  isClearable={true}
                  onChange={onSubjectsSelectChange}
                  value={
                    subjectsOptions.find(
                      ({ value }) => value === fields["subject"]
                    ) || null
                  }
                  options={subjectsOptions}
                />
                <FormFeedback>{errors["subject"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Select
                  name="course"
                  placeholder="Curs"
                  noOptionsMessage={() =>
                    !fields["subject"] ? (
                      <span>Selectati o disciplina</span>
                    ) : (
                      <span>Fara rezultat</span>
                    )
                  }
                  isClearable={true}
                  onChange={onCoursesSelectChange}
                  value={
                    courseOptions.find(
                      ({ value }) => value === fields["course"]
                    ) || null
                  }
                  options={courseOptions}
                />
                <FormFeedback>{errors["course"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between mt-5">
                <Button
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={loading || disabledSubmit}
                >
                  Salvare
                </Button>
                <Button outline color="secondary" onClick={onCancel}>
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default AddUserSubscription;
