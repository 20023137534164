// Packages
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Button,
  Input,
  InputGroup,
  Badge,
} from "reactstrap";
import axios from "axios";
import { BsSearch, BsPaypal } from "react-icons/bs";
// Utils
import { getRequestHeaders, catchHandler } from "../utils";
import { taxLabels, taxValues } from "../utils/constants";
// Components
import Pagination from "../components/Pagination";
// Styling
import "./Receipts.css";

const Receipts = React.memo((props) => {
  const { state: locationState = {} } = useLocation();
  const { pageNumber = 1 } = locationState;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [totalCount, setTotalCount] = useState(0);
  const [receipts, setReceipts] = useState([]);
  const [paidStatus, setPaidStatus] = useState(undefined);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const [searchValue, setSearchValue] = useState("");
  const [searchTriggerValue, setSearchTriggerValue] = useState("");

  const getData = useCallback((currentPage, searchTriggerValue) => {
    setLoading(true);

    axios
      .get(
        `/api/receipts/list-receipts`,
        getRequestHeaders(
          Object.assign(
            {
              page: currentPage,
            },
            searchTriggerValue && {
              search: searchTriggerValue,
            }
          )
        )
      )
      .then((res) => {
        const { data = {} } = res;
        const { paidStatus, items = [], totalCount = 0 } = data;
        setPaidStatus(paidStatus);
        setReceipts(items);
        setTotalCount(totalCount);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getData(currentPage, searchTriggerValue);
  }, [currentPage, searchTriggerValue]);

  const getRows = useCallback(
    () =>
      receipts.length ? (
        receipts.map(({ orderDesc, amount, action }, id) => (
          <tr key={`receipt-row-${id}`}>
            <td>{orderDesc}</td>
            <td>{amount}</td>
            <td>
              {Boolean(Number(action)) ? "Plata esuata" : "Plata cu success"}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={10} className="text-center">
            <span>Fara rezultat</span>
          </td>
        </tr>
      ),
    [receipts]
  );

  const onSearchChange = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setSearchValue(() => value);
  }, []);

  const handleSearch = useCallback(
    (event) => {
      const { type, key } = event;

      if (type === "click" || (type === "keyup" && key === "Enter")) {
        setSearchTriggerValue(searchValue);
        setCurrentPage(1);
      }
    },
    [searchValue]
  );

  const onPayment = useCallback(() => {
    setLoading(true);

    axios
      .get(`/api/payment/get-redirect`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { redirectUrl = {} } = data;
        window.location.href = redirectUrl;
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container className="mt-5 mb-5" style={{ maxWidth: "1400px" }}>
      <Row className="mt-5">
        <Col className="d-flex justify-content-between align-items-center">
          <span className="">
            <h3>Plati ({totalCount})</h3>
          </span>
        </Col>
      </Row>
      <Row className="mt-1 justify-content-between">
        <Col className="col-4 justify-content-start">
          {paidStatus === taxValues.NOT_APPLICABLE ? (
            <>
              <Badge className="p-2" color="secondary" pill>
                <h6>{taxLabels.NOT_APPLICABLE}</h6>
              </Badge>
              <h6>
                Pentru a obtine mai multe informatii te invitam sa intri pe
                grupul de Whatsapp, accesand meniul de mai sus
              </h6>
            </>
          ) : paidStatus === taxValues.PAID ? (
            <>
              <Badge className="p-2" color="success" pill>
                <h6>{taxLabels.PAID}</h6>
              </Badge>
              <h6>
                Pentru a obtine mai multe informatii te invitam sa intri pe
                grupul de Whatsapp, accesand meniul de mai sus
              </h6>
            </>
          ) : paidStatus === taxValues.TO_PAY ? (
            <Badge className="p-2" color="warning" pill>
              <h6>{taxLabels.TO_PAY}</h6>
            </Badge>
          ) : paidStatus === taxValues.NOT_PAID ? (
            <Badge className="p-2" color="danger" pill>
              <h6>{taxLabels.NOT_PAID}</h6>
            </Badge>
          ) : null}
        </Col>
        <Col className="col-4 d-inline-flex justify-content-end">
          {paidStatus === taxValues.TO_PAY ||
          paidStatus === taxValues.NOT_PAID ? (
            <Button
              disabled={loading}
              color="success"
              size="sm"
              onClick={onPayment}
            >
              <BsPaypal /> Plateste taxa acum
            </Button>
          ) : null}
        </Col>
        <Col className="col-4 justify-content-end">
          <InputGroup>
            <Input
              type="text"
              placeholder="Search..."
              onChange={onSearchChange}
              onKeyUp={handleSearch}
              value={searchValue}
            />
            <Button
              disabled={loading}
              color="secondary"
              size="sm"
              onClick={handleSearch}
            >
              <BsSearch />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Table hover>
            <thead>
              <tr>
                <th>Nume</th>
                <th>Valoare</th>
                <th>Plata</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={10} className="text-center">
                    <Spinner
                      color="dark"
                      style={{ width: "3rem", height: "3rem" }}
                    />
                  </td>
                </tr>
              ) : (
                getRows()
              )}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            onSetPage={setCurrentPage}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <h3>Instructiuni</h3>
        <p>
          Citește cu răbdare instrucțiunile și identifică în care dintre
          cazurile descrise te afli. Acționează conform instrucțiunilor cazului
          respectiv.
        </p>
        <p>
          Cazul 1 - Te-ai înscris la unul dintre cursuri. Folosește butonul
          verde „Plătește taxa acum” pentru a achita taxa de legitimare ca
          sportiv la Aeroclubul României (valabil doar pentru cursurile
          subvenționate - gratuite), apoi alătură-te grupului de Whatsapp
          folosind link-ul din secțiunea „Whatsapp”.
        </p>
        <p>
          Cazul 2 - Reînscrierea/Înscrierea la un curs nou subvenționat in anul current.
          Se aplică dacă: - nu am fost admis la zbor în anul precedent în cadrul
          unui curs subvenționat SAU - am absolvit cursul subvenționat în anii
          precedenți. Dacă dorești înscrierea în cadrul unui curs subvenționat în
          anul curent, accesează secțiunea „Înrolări” și folosește butonul
          albastru - „Înrolare nouă”.
        </p>
        <p>
          Cazul 3 - Continuarea pregătirii în cadrul unui curs subvenționat în
          anul curent. Dacă în anul anterior ai fost admis la modulul de
          pregătire practică (admis la zbor) în cadrul unui curs subvenționat și
          dorești continuarea cursului respectiv, achită taxa de legitimare
          folosind butonul verde „Plătește taxa acum”. Asigură-te că în
          secțiunea „Înrolări” cursul respectiv are status „Activ”. Dacă nu,
          contactează-ți instructorul de zbor.
        </p>
        <p>
          Cazul 4 - Cursuri în regim de contract (contra cost). Dacă dorești
          înscrierea în cadrul unui curs în regim de contract (contra cost) în
          anul curent, accesează secțiunea „Înrolări” și folosește butonul
          albastru - „Înrolare nouă”.
        </p>
        <p>
          Cazul 5 - Sportiv în lotul județean. Folosește butonul verde „Plătește
          taxa acum” pentru achitarea taxei de legitimare aferente anului în
          curs.
        </p>
      </Row>
    </Container>
  );
});

export default Receipts;
