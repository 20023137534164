// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
import {
  BsCheckCircleFill,
  BsLockFill,
  BsMortarboardFill,
  BsTrashFill,
  BsUnlockFill,
  BsXCircleFill,
} from "react-icons/bs";
// Utils
import {
  catchHandler,
  entriesToOptions,
  getRequestHeaders,
  getSelectStyles,
  collaborationTypesLabels,
  taxOptions,
  yearsOptions,
  enumToOptions,
} from "../utils";
// Styling
import "./Subscriptions.css";
import { collaborationTypes, taxValues } from "../utils/constants";

const Subscriptions = React.memo((props) => {
  const { id: userId } = useParams();
  const rootPathname = useMemo(() => "/users-management", []);
  const [userEmail, setUserEmail] = useState("");
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [subjectsOptions, setSubjectsOptions] = useState({});
  const [locationsOptions, setLocationsOptions] = useState({});
  const [initialTaxPaid, setInitialTaxPaid] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    tax: "",
    membershipYear: "",
    subscriptions: [],
    activityComment: "",
  });
  const [errors, setErrors] = useState({});
  const collaborationTypesOptions = useMemo(
    () => enumToOptions(collaborationTypesLabels),
    []
  );
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`/api/courses/select-courses`, getRequestHeaders())
      .then((res) => {
        const { data = [] } = res;
        setCoursesOptions(data.map(entriesToOptions));
      })
      .catch(catchHandlerBinded);

    axios
      .get(`/api/subjects/select-mapped-subjects-options`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        setSubjectsOptions(data);
      })
      .catch(catchHandlerBinded);

    axios
      .get(
        `/api/locations/select-mapped-locations-options`,
        getRequestHeaders()
      )
      .then((res) => {
        const { data = {} } = res;
        setLocationsOptions(data);
      })
      .catch(catchHandlerBinded);

    axios
      .get(`/api/users/get-subscriptions/${userId}`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const {
          taxPaid = false,
          taxNotApplicable = false,
          membershipYear = "",
          subscriptions = [],
          activityComment,
          email,
        } = data;
        setFields({
          tax: taxNotApplicable
            ? taxValues.NOT_APPLICABLE
            : taxPaid
            ? taxValues.PAID
            : taxValues.NOT_PAID,
          membershipYear,
          subscriptions,
          activityComment,
        });
        setUserEmail(email);
        setInitialTaxPaid(taxPaid);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .patch(
          `/api/users/edit-subscriptions/${userId}`,
          fields,
          getRequestHeaders()
        )
        .then((res) => {
          const { data = {} } = res;
          const { email = "" } = data;
          toast.success(
            `Inrolarile utilizatorului [${email}] au fost modificate cu succes`
          );
          history.push(rootPathname);
        })
        .catch((err) => {
          const { name = "", description = "" } = catchHandler(history, err);
          setErrors({ name, description });
          setLoading(false);
        });
    },
    [userId, history, fields, rootPathname]
  );

  const onCancel = useCallback(
    (event) => {
      event.preventDefault();
      history.push(rootPathname);
    },
    [history, rootPathname]
  );

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name: rawName, value } = target;
    const [name, subscriptionIdx] = rawName.split("-");

    if (subscriptionIdx === undefined) {
      setFields((prevFields) => ({ ...prevFields, [name]: value }));
    } else {
      setFields((prevFields) => {
        const { subscriptions = [] } = prevFields;
        const newSubscriptions = [...subscriptions];
        newSubscriptions[subscriptionIdx][name] = value;
        return {
          ...prevFields,
          subscriptions: newSubscriptions,
        };
      });
    }
    setDirty(true);
  }, []);

  const onSelectChange = useCallback(
    (option, action) => {
      const { value } = option || {};
      const { name: rawName } = action || {};
      const [name, subscriptionIdx] = rawName.split("-");

      if (subscriptionIdx === undefined) {
        setFields((prevFields) => ({ ...prevFields, [name]: value }));
      } else {
        setFields((prevFields) => {
          const { subscriptions = [] } = prevFields;
          const newFields = { ...prevFields };
          const newSubscriptions = [...subscriptions];
          newSubscriptions[subscriptionIdx][name] = value;
          if (name === "collaborationType") {
            newSubscriptions[subscriptionIdx]["course"] = "";
            newSubscriptions[subscriptionIdx]["subject"] = "";
            newSubscriptions[subscriptionIdx]["location"] = "";
            newSubscriptions[subscriptionIdx]["collaborationType"] = value;
            const hasSubventionatCourses = newSubscriptions.some(
              ({ collaborationType }) =>
                collaborationType === collaborationTypes.SUBVENTIONAT ||
                collaborationType === collaborationTypes.LOT
            );
            newFields["tax"] = hasSubventionatCourses
              ? initialTaxPaid
                ? taxValues.PAID
                : taxValues.NOT_PAID
              : taxValues.NOT_APPLICABLE;
          } else if (name === "course") {
            newSubscriptions[subscriptionIdx]["subject"] = "";
            newSubscriptions[subscriptionIdx]["location"] = "";
          } else if (name === "subject") {
            newSubscriptions[subscriptionIdx]["location"] = "";
          }

          return {
            ...newFields,
            subscriptions: newSubscriptions,
          };
        });
      }
      setDirty(true);
    },
    [initialTaxPaid]
  );

  const onAddSubscriptionClick = useCallback(() => {
    setFields((prevFields) => {
      const { subscriptions = [] } = prevFields;
      const newSubscriptions = [...subscriptions];
      newSubscriptions.push({
        course: "",
        subject: "",
        location: "",
        enrollmentYear: new Date().getFullYear(),
        admissionYear: "",
        graduationYear: "",
        collaborationType: "",
        blocked: false,
        approved: false,
        graduated: false,
      });
      return {
        ...prevFields,
        subscriptions: newSubscriptions,
      };
    });
  }, []);

  const onBlockSubscriptionClick = useCallback((subscriptionIdx) => {
    setFields((prevFields) => {
      const { subscriptions = [] } = prevFields;
      const newSubscriptions = [...subscriptions];
      newSubscriptions[subscriptionIdx]["blocked"] =
        !newSubscriptions[subscriptionIdx]["blocked"];
      return {
        ...prevFields,
        subscriptions: newSubscriptions,
      };
    });
    setDirty(true);
  }, []);

  const onApproveSubscriptionClick = useCallback((subscriptionIdx) => {
    setFields((prevFields) => {
      const { subscriptions = [] } = prevFields;
      const newSubscriptions = [...subscriptions];
      const newValue = !newSubscriptions[subscriptionIdx]["approved"];
      newSubscriptions[subscriptionIdx]["approved"] = newValue;
      newSubscriptions[subscriptionIdx]["admissionYear"] = newValue
        ? new Date().getFullYear()
        : "";
      return {
        ...prevFields,
        subscriptions: newSubscriptions,
      };
    });
    setDirty(true);
  }, []);

  const onGraduationSubscriptionClick = useCallback((subscriptionIdx) => {
    setFields((prevFields) => {
      const { subscriptions = [] } = prevFields;
      const newSubscriptions = [...subscriptions];
      const newValue = !newSubscriptions[subscriptionIdx]["graduated"];
      newSubscriptions[subscriptionIdx]["graduated"] = newValue;
      newSubscriptions[subscriptionIdx]["graduationYear"] = newValue
        ? new Date().getFullYear()
        : "";
      return {
        ...prevFields,
        subscriptions: newSubscriptions,
      };
    });
    setDirty(true);
  }, []);

  const onRemoveSubscriptionClick = useCallback((subscriptionIdx) => {
    setFields((prevFields) => {
      const { subscriptions = [] } = prevFields;
      const newSubscriptions = [...subscriptions];
      newSubscriptions.splice(subscriptionIdx, 1);
      return {
        ...prevFields,
        subscriptions: newSubscriptions,
      };
    });
    setDirty(true);
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" lg={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mx-auto text-center">Administrare Inrolari</h2>
            {userEmail && (
              <h4 className="mb-5 mx-auto text-center">( {userEmail} )</h4>
            )}
            <Form>
              {fields.subscriptions?.map((subscription, idx) => (
                <Row
                  key={`subscription-container-row-${idx}`}
                  className="subscription-container"
                >
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <h5 className="subscription-heading">
                      Curs {idx + 1}&nbsp;
                      {subscription.graduated
                        ? "(Absolvit)"
                        : subscription.blocked
                        ? "(Blocat)"
                        : subscription.approved
                        ? "(Aprobat la zbor)"
                        : "(Activ - In asteptare)"}
                    </h5>
                    <div className="subscription-divider d-flex inline-flex">
                      {!subscription.graduated && (
                        <Button
                          color={subscription.blocked ? "info" : "secondary"}
                          className="me-2 d-flex align-items-center"
                          onClick={onBlockSubscriptionClick.bind(null, idx)}
                          disabled={loading}
                        >
                          {subscription.blocked ? (
                            <>
                              <BsUnlockFill className="me-1" /> {"Deblocare"}
                            </>
                          ) : (
                            <>
                              <BsLockFill className="me-1" /> {"Blocare"}
                            </>
                          )}
                        </Button>
                      )}
                      {!subscription.blocked && !subscription.graduated && (
                        <Button
                          color={subscription.approved ? "warning" : "success"}
                          className="me-2 d-flex align-items-center"
                          onClick={onApproveSubscriptionClick.bind(null, idx)}
                          disabled={loading}
                        >
                          {subscription.approved ? (
                            <>
                              <BsXCircleFill className="me-1" />{" "}
                              {"Revocare aprobare de zbor"}
                            </>
                          ) : (
                            <>
                              <BsCheckCircleFill className="me-1" />{" "}
                              {"Aprobare de zbor"}
                            </>
                          )}
                        </Button>
                      )}
                      {!subscription.blocked && subscription.approved && (
                        <Button
                          color={subscription.graduated ? "warning" : "success"}
                          className="me-2 d-flex align-items-center"
                          onClick={onGraduationSubscriptionClick.bind(
                            null,
                            idx
                          )}
                          disabled={loading}
                        >
                          {subscription.graduated ? (
                            <>
                              <BsXCircleFill className="me-1" />{" "}
                              {"Anulare absolvire"}
                            </>
                          ) : (
                            <>
                              <BsMortarboardFill className="me-1" />{" "}
                              {"Absolvire"}
                            </>
                          )}
                        </Button>
                      )}
                      <Button
                        color={"danger"}
                        className="me-2 d-flex align-items-center"
                        onClick={onRemoveSubscriptionClick.bind(null, idx)}
                        disabled={loading}
                      >
                        <BsTrashFill className="me-1" /> Stergere
                      </Button>
                    </div>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for={`collaborationType-${idx}`}>
                        Tip Colaborare
                      </Label>
                      <Select
                        id={`collaborationType-${idx}`}
                        name={`collaborationType-${idx}`}
                        className={
                          Boolean(errors[`collaborationType-${idx}`])
                            ? "is-invalid"
                            : ""
                        }
                        placeholder="Colaborare"
                        noOptionsMessage={() => <span>Fara rezultat</span>}
                        styles={getSelectStyles(
                          Boolean(errors[`collaborationType-${idx}`])
                        )}
                        isLoading={loading}
                        type="text"
                        value={
                          collaborationTypesOptions.find(
                            ({ value }) =>
                              value === subscription.collaborationType
                          ) || null
                        }
                        options={collaborationTypesOptions}
                        onChange={onSelectChange}
                      />
                      <FormFeedback>
                        {errors[`collaborationType-${idx}`]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for={`enrollmentYear-${idx}`}>An Inrolare</Label>
                      <Input
                        id={`enrollmentYear-${idx}`}
                        name={`enrollmentYear-${idx}`}
                        className={
                          Boolean(errors[`enrollmentYear-${idx}`])
                            ? "is-invalid"
                            : ""
                        }
                        styles={getSelectStyles(
                          Boolean(errors[`enrollmentYear-${idx}`])
                        )}
                        disabled={loading}
                        type="number"
                        value={subscription.enrollmentYear || ""}
                        onChange={onInputChange}
                      />
                      <FormFeedback>
                        {errors[`enrollmentYear-${idx}`]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for={`admissionYear-${idx}`}>An Admitere</Label>
                      <Input
                        id={`admissionYear-${idx}`}
                        name={`admissionYear-${idx}`}
                        className={
                          Boolean(errors[`admissionYear-${idx}`])
                            ? "is-invalid"
                            : ""
                        }
                        styles={getSelectStyles(
                          Boolean(errors[`admissionYear-${idx}`])
                        )}
                        disabled={loading}
                        type="number"
                        value={subscription.admissionYear || ""}
                        onChange={onInputChange}
                      />
                      <FormFeedback>
                        {errors[`admissionYear-${idx}`]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for={`graduationYear-${idx}`}>An Absolvire</Label>
                      <Input
                        id={`graduationYear-${idx}`}
                        name={`graduationYear-${idx}`}
                        className={
                          Boolean(errors[`graduationYear-${idx}`])
                            ? "is-invalid"
                            : ""
                        }
                        styles={getSelectStyles(
                          Boolean(errors[`graduationYear-${idx}`])
                        )}
                        disabled={loading}
                        type="number"
                        value={subscription.graduationYear || ""}
                        onChange={onInputChange}
                      />
                      <FormFeedback>
                        {errors[`graduationYear-${idx}`]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for={`course-${idx}`}>Cursul</Label>
                      <Select
                        id={`course-${idx}`}
                        name={`course-${idx}`}
                        className={
                          Boolean(errors[`course-${idx}`]) ? "is-invalid" : ""
                        }
                        placeholder="Alegeti cursul"
                        noOptionsMessage={() =>
                          !subscription.collaborationType ? (
                            <span>Selectati un tip de colaborare</span>
                          ) : (
                            <span>Fara rezultat</span>
                          )
                        }
                        styles={getSelectStyles(
                          Boolean(errors[`course-${idx}`])
                        )}
                        isLoading={loading}
                        type="text"
                        value={(subscription.collaborationType
                          ? coursesOptions
                          : []
                        )?.filter(({ value }) => subscription.course === value)}
                        options={
                          subscription.collaborationType ? coursesOptions : []
                        }
                        onChange={onSelectChange}
                      />
                      <FormFeedback>{errors[`course-${idx}`]}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for={`subject-${idx}`}>Disciplina</Label>
                      <Select
                        id={`subject-${idx}`}
                        name={`subject-${idx}`}
                        className={
                          Boolean(errors[`subject-${idx}`]) ? "is-invalid" : ""
                        }
                        placeholder="Alegeti disciplina"
                        noOptionsMessage={() =>
                          !subscription.course ? (
                            <span>Selectati un curs</span>
                          ) : (
                            <span>Fara rezultat</span>
                          )
                        }
                        styles={getSelectStyles(
                          Boolean(errors[`subject-${idx}`])
                        )}
                        isLoading={loading}
                        type="text"
                        value={(subscription.course
                          ? subjectsOptions[subscription.course]?.filter(
                              ({ deactivatedForSubventionat }) =>
                                subscription.collaborationType !==
                                  collaborationTypes.SUBVENTIONAT ||
                                !deactivatedForSubventionat
                            ) || []
                          : []
                        )?.filter(
                          ({ value }) => subscription.subject === value
                        )}
                        options={
                          subscription.course
                            ? subjectsOptions[subscription.course]?.filter(
                                ({ deactivatedForSubventionat }) =>
                                  subscription.collaborationType !==
                                    collaborationTypes.SUBVENTIONAT ||
                                  !deactivatedForSubventionat
                              ) || []
                            : []
                        }
                        onChange={onSelectChange}
                      />
                      <FormFeedback>{errors[`subject-${idx}`]}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for={`location-${idx}`}>Locatie</Label>
                      <Select
                        id={`location-${idx}`}
                        name={`location-${idx}`}
                        className={
                          Boolean(errors[`location-${idx}`]) ? "is-invalid" : ""
                        }
                        placeholder="Alegeti locatia"
                        noOptionsMessage={() =>
                          !subscription.subject ? (
                            <span>Selectati o disciplina</span>
                          ) : (
                            <span>Fara rezultat</span>
                          )
                        }
                        styles={getSelectStyles(
                          Boolean(errors[`location-${idx}`])
                        )}
                        isLoading={loading}
                        type="text"
                        value={(subscription.subject
                          ? locationsOptions[subscription.subject] || []
                          : []
                        )?.filter(
                          ({ value }) => subscription.location === value
                        )}
                        options={
                          subscription.subject
                            ? locationsOptions[subscription.subject] || []
                            : []
                        }
                        onChange={onSelectChange}
                      />
                      <FormFeedback>{errors[`location-${idx}`]}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              ))}
              <FormGroup className="d-flex justify-content-start mt-1">
                <Button
                  color="success"
                  onClick={onAddSubscriptionClick}
                  disabled={loading}
                >
                  Adaugare Curs
                </Button>
              </FormGroup>
              <Row
                key="subscription-container-global-settings"
                className="subscription-container"
              >
                <Col md={6}>
                  <FormGroup>
                    <Label for="tax">Taxa legitimare</Label>
                    <Select
                      id="tax"
                      name="tax"
                      className={
                        Boolean(Boolean(errors["tax"])) ? "is-invalid" : ""
                      }
                      placeholder="Taxa legitimare"
                      noOptionsMessage={() => <span>Fara rezultat</span>}
                      styles={getSelectStyles(Boolean(errors["tax"]))}
                      isLoading={loading}
                      type="text"
                      value={
                        taxOptions.find(
                          ({ value }) => value === fields["tax"]
                        ) || null
                      }
                      options={taxOptions}
                      onChange={onSelectChange}
                    />
                    <FormFeedback>{errors["tax"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="membershipYear">An Legitimare</Label>
                    <Select
                      id="membershipYear"
                      name="membershipYear"
                      className={
                        Boolean(Boolean(errors["membershipYear"]))
                          ? "is-invalid"
                          : ""
                      }
                      placeholder="An"
                      noOptionsMessage={() => <span>Fara rezultat</span>}
                      styles={getSelectStyles(
                        Boolean(errors["membershipYear"])
                      )}
                      isLoading={loading}
                      type="text"
                      value={
                        yearsOptions.find(
                          ({ value }) => value === fields["membershipYear"]
                        ) || null
                      }
                      options={yearsOptions}
                      onChange={onSelectChange}
                    />
                    <FormFeedback>{errors["membershipYear"]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="activityComment">Comentarii</Label>
                <Input
                  id="activityComment"
                  name="activityComment"
                  type="textarea"
                  invalid={Boolean(errors["activityComment"])}
                  value={fields["activityComment"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["activityComment"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between mt-5">
                <Button
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={loading || !dirty}
                >
                  Salvare
                </Button>
                <Button outline color="secondary" onClick={onCancel}>
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default Subscriptions;
