import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Row,
  Col,
  Container,
} from "reactstrap";
import { DatePicker } from "reactstrap-date-picker";
import Select from "react-select";
import {
  collaborationTypes,
  existingMemberOptions,
  getSelectStyles,
  calculateAge,
  AGE_LIMITS,
  MONTH_NAMES,
  yearsOptions,
  getRequestHeaders,
  catchHandler,
  formatDateToDDMMYYYY,
} from "../../utils";

class ThirdStep extends React.Component {
  state = {
    birthDate: "",
    years: 0,
    months: 0,
    collaboration: "",
    existingMember: existingMemberOptions.NEW,
    membershipYear: "",
    membershipYearTouched: false,
    touched: false,
    valid: false,
    Files: "",
    FilesTouched: false,
    UploadingFile: false,
    fileRef: "",
    fileError: "",
    open: "",
    activeMembruInLot: false,
    subventionatLimit: new Date(),
    agreementLimit: new Date(),

    // test data
    // birthDate: "2007-12-12T12:00:00.000Z",
    // years: 16,
    // months: 0,
    // touched: true,
  };

  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onFilesInput = this.onFilesInput.bind(this);
    sessionStorage.removeItem("isSubventionat");
  }

  componentDidMount() {
    axios
      .get(`/api/wizard/get-active-membru-in-lot`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { activeMembruInLot } = data;
        this.setState({
          activeMembruInLot: Boolean(activeMembruInLot),
        });
      })
      .catch(catchHandler);
    axios
      .get(`/api/wizard/get-subventionat`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { limit } = data;
        this.setState({
          subventionatLimit: new Date(limit),
        });
      })
      .catch(catchHandler);
    axios
      .get(`/api/wizard/get-agreement`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { limit } = data;
        this.setState({
          agreementLimit: new Date(limit),
        });
      })
      .catch(catchHandler);
  }

  // Important for Wizard
  isValidated() {
    const {
      birthDate,
      years,
      months,
      collaboration,
      existingMember,
      membershipYear,
      fileRef,
    } = this.state;

    const ageInMonths = years * 12 + months;

    this.setState({
      touched: true,
      membershipYearTouched: true,
      FilesTouched: true,
    });

    return (
      (existingMember === existingMemberOptions.EXISTING
        ? Boolean(birthDate && collaboration && membershipYear)
        : Boolean(
            birthDate &&
              collaboration &&
              (collaboration === collaborationTypes.LOT ||
                (ageInMonths > AGE_LIMITS.OVER
                  ? true
                  : ageInMonths >= AGE_LIMITS.UNDER))
          )) &&
      (existingMember === existingMemberOptions.NEW &&
      birthDate &&
      ageInMonths >= AGE_LIMITS.UNDER &&
      ageInMonths < AGE_LIMITS.MINOR
        ? Boolean(fileRef)
        : true)
    );
  }

  toggle(id) {
    const { open } = this.state;
    if (open === id) {
      this.setState({ open: "" });
    } else {
      this.setState({ open: id });
    }
  }

  onSelectChange(option) {
    const { value = "" } = option || {};
    this.setState({
      membershipYear: value,
      membershipYearTouched: true,
      touched: true,
    });
  }

  onDateChange(value) {
    if (!value) {
      this.setState({
        birthDate: "",
        years: 0,
        months: 0,
      });
      return;
    }

    const [years, months] = calculateAge(value);

    this.setState({
      birthDate: value,
      years,
      months,
      touched: true,
    });
  }

  onRadioChange(event, ...params) {
    const { target = {} } = event;
    const { name, value } = target;

    if (name === "collaboration" && value === collaborationTypes.SUBVENTIONAT) {
      sessionStorage.setItem("isSubventionat", true);
    } else if (name === "collaboration") {
      sessionStorage.removeItem("isSubventionat");
    }

    this.setState({
      [name]: value,
      touched: true,
    });
  }

  onFilesInput(event) {
    const { target = {} } = event;
    const { files } = target;

    this.setState({ ["FilesTouched"]: true });

    if (!files?.length) {
      this.setState({ fileRef: "" });
      return;
    }

    // verify files
    for (let file of files) {
      const { type } = file;
      // pdf|jpeg|jpg|png limit - sync with server
      if (!type.startsWith("image/") && type !== "application/pdf") {
        this.setState({
          fileError: "Poti incarca doar fisiere PDF sau Imagini",
        });
        return;
      }

      // 20Mb limit - sync with server
      const maxFileSizeInMB = 20;
      const maxFileSizeInB = 1024 * 1024 * maxFileSizeInMB;

      if (file.size > maxFileSizeInB) {
        this.setState({
          fileError: "Poti incarca doar de maxim 20Mb",
        });
        return;
      }
    }

    const formData = new FormData();
    for (let file of files) {
      formData.append("files", file);
    }

    this.setState({ ["UploadingFile"]: true });
    axios
      .post(
        "/api/public-files/create-file",
        formData,
        getRequestHeaders({ requestHasFilesFlag: true })
      )
      .then(({ data }) => {
        const { fileRef = "" } = data || {};
        this.setState({ fileRef, fileError: "" });
        toast.success("Documentul a fost salvat cu succes");
      })
      .catch((err) => {
        this.setState({ fileRef: "", fileError: "Fisierul nu este valid" });
        catchHandler(null, err);
      })
      .finally(() => {
        this.setState({ ["UploadingFile"]: false });
      });
  }

  render() {
    const {
      birthDate,
      years,
      months,
      touched,
      collaboration,
      existingMember,
      membershipYear,
      membershipYearTouched,
      Files,
      FilesTouched,
      UploadingFile,
      fileError,
      fileRef,
      open,
      activeMembruInLot,
      subventionatLimit,
      agreementLimit,
    } = this.state;
    const ageInMonths = years * 12 + months;
    const disableSubventionat = Date.now() > subventionatLimit.getTime();

    return (
      <>
        <Row>
          <Col md={6}>
            <h5>Data nasterii</h5>
            <FormGroup className="mb-3 styled-date-picker-container">
              <div
                className={Boolean(touched && !birthDate) ? "is-invalid" : ""}
              >
                <DatePicker
                  id="DataNasterii"
                  className={Boolean(touched && !birthDate) ? "is-invalid" : ""}
                  styles={getSelectStyles(Boolean(touched && !birthDate))}
                  name="DataNasterii"
                  dateFormat="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  monthLabels={MONTH_NAMES}
                  minDate="1900-01-01T00:00:00.000Z"
                  maxDate={new Date().toISOString()}
                  value={birthDate}
                  onChange={this.onDateChange}
                  pickMonthElement={"default"}
                  autoComplete="off"
                />
              </div>
              <FormFeedback>
                {touched && !birthDate && "Camp obligatoriu"}
              </FormFeedback>
            </FormGroup>
            {touched && birthDate && ageInMonths >= AGE_LIMITS.UNDER ? (
              <>
                <FormGroup
                  tag="fieldset"
                  className="mb-3"
                  onChange={this.onRadioChange}
                >
                  <h5>Tip de colaborare</h5>
                  <FormGroup check>
                    <Input
                      id="input-checkbox-subventionat"
                      name="collaboration"
                      readOnly
                      type="radio"
                      value={collaborationTypes.SUBVENTIONAT}
                      checked={
                        collaboration === collaborationTypes.SUBVENTIONAT
                      }
                      disabled={
                        disableSubventionat || ageInMonths > AGE_LIMITS.OVER
                      }
                    />{" "}
                    <Label for="input-checkbox-subventionat" check>
                      Subventionat (gratuit, doar pentru cetățeni români)
                      <br />
                      {ageInMonths > AGE_LIMITS.OVER ? (
                        <h6>
                          Ne pare rau! Varsta este prea mare pentru aceasta
                          optiune.
                        </h6>
                      ) : disableSubventionat ? (
                        <h6>
                          Ne pare rau! Termenul pentru inscrierile subventionate
                          a expirat. Te asteptam in sesiunea urmatoare!
                        </h6>
                      ) : null}
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      id="input-checkbox-contract"
                      name="collaboration"
                      readOnly
                      type="radio"
                      value={collaborationTypes.CONTRACT}
                      checked={collaboration === collaborationTypes.CONTRACT}
                    />{" "}
                    <Label for="input-checkbox-contract" check>
                      Contract (contra cost)
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      id="input-checkbox-lot"
                      name="collaboration"
                      readOnly
                      type="radio"
                      value={collaborationTypes.LOT}
                      checked={collaboration === collaborationTypes.LOT}
                      disabled={!activeMembruInLot}
                    />{" "}
                    <Label for="input-checkbox-lot" check>
                      Membru in lot
                    </Label>
                  </FormGroup>
                </FormGroup>
                <FormGroup
                  tag="fieldset"
                  className="mb-3"
                  onChange={this.onRadioChange}
                >
                  <h5>Tip membru</h5>
                  <FormGroup check>
                    <Input
                      id="input-checkbox-new-member"
                      name="existingMember"
                      readOnly
                      type="radio"
                      value={existingMemberOptions.NEW}
                      checked={existingMember === existingMemberOptions.NEW}
                    />{" "}
                    <Label for="input-checkbox-new-member" check>
                      Membru nou
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      id="input-checkbox-existing"
                      name="existingMember"
                      readOnly
                      type="radio"
                      value={existingMemberOptions.EXISTING}
                      checked={
                        existingMember === existingMemberOptions.EXISTING
                      }
                      disabled={collaboration !== collaborationTypes.LOT || !activeMembruInLot}
                    />{" "}
                    <Label for="input-checkbox-existing" check>
                      Membru existent
                    </Label>
                  </FormGroup>
                </FormGroup>
                {existingMember === existingMemberOptions.EXISTING ? (
                  <FormGroup>
                    <Select
                      name="membershipYear"
                      className={
                        Boolean(membershipYearTouched && !membershipYear)
                          ? "is-invalid"
                          : ""
                      }
                      placeholder="An inscriere"
                      noOptionsMessage={() => <span>Fara rezultat</span>}
                      isClearable={true}
                      onChange={this.onSelectChange}
                      styles={getSelectStyles(
                        Boolean(membershipYearTouched && !membershipYear)
                      )}
                      value={
                        yearsOptions.find(
                          ({ value }) => value === membershipYear
                        ) || null
                      }
                      options={yearsOptions}
                    />
                    <FormFeedback>
                      {membershipYearTouched &&
                        !membershipYear &&
                        "Camp obligatoriu"}
                    </FormFeedback>
                  </FormGroup>
                ) : null}
              </>
            ) : null}
            {touched && birthDate && ageInMonths < AGE_LIMITS.UNDER ? (
              <h6>Varsta prea mica pentru zbor.</h6>
            ) : existingMember === existingMemberOptions.NEW &&
              touched &&
              birthDate &&
              (ageInMonths < AGE_LIMITS.ALMOST_UNDER ||
                (ageInMonths > AGE_LIMITS.ALMOST_OVER &&
                  ageInMonths <= AGE_LIMITS.OVER &&
                  collaboration === collaborationTypes.SUBVENTIONAT)) ? (
              <h6>
                Ținând cont de vârsta dumneavoastră, există șanse să nu puteți
                zbura în regim subvenționat (gratuit) în cadrul acestui curs.
              </h6>
            ) : null}
          </Col>
          <Col md={6}>
            <Container>
              <Accordion open={open} toggle={this.toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="1">
                    1. Care sunt disciplinele subventionate (gratuite)?
                  </AccordionHeader>
                  <AccordionBody accordionId="1">
                    Disciplinele subventionate sunt: avion ultrausor, planorism
                    si parasutism.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="2">
                    2. Sunt eligibil pentru cursurile subvenționate (gratuite)?
                  </AccordionHeader>
                  <AccordionBody accordionId="2">
                    Dacă ai vârsta cuprinsă între 15 ani împliniţi și 23 de ani
                    neîmpliniți, da, ești eligibil pentru cursurile
                    subvenționate (gratuite) de planorism, parasutism si avion
                    ultrausor. Cursurile pentru obtinerea licentei PPL se pot
                    face exclusiv contra-cost.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="3">
                    3. Care sunt costurile cursurilor nesubvenționate
                    (contract)?
                  </AccordionHeader>
                  <AccordionBody accordionId="3">
                    Costurile cursurilor pe care le organizăm pot fi găsite pe
                    site-ul nostru:
                    <ul>
                      <li>
                        <a
                          href="http://aeroclubulromaniei.ro/page/sca-cursuri-oferite"
                          target="_blank"
                        >
                          Avion
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://aeroclubulromaniei.ro/page/scau-cursuri-oferite"
                          target="_blank"
                        >
                          Avion ultrausor
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://aeroclubulromaniei.ro/page/scpl-cursuri-oferite"
                          target="_blank"
                        >
                          Planor
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://aeroclubulromaniei.ro/page/scpa-cursuri-oferite"
                          target="_blank"
                        >
                          Parasutism
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://aeroclubulromaniei.ro/page/scb-cursuri-oferite"
                          target="_blank"
                        >
                          Balon cu aer cald
                        </a>
                      </li>
                    </ul>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="4">
                    4. Pot să mă înscriu la un curs subventionat dacă împlinesc
                    vârsta de 23 de ani anul acesta?
                  </AccordionHeader>
                  <AccordionBody accordionId="4">
                    Da, te poți înscrie la curs. În cazul în care te-ai înscris
                    la curs la vârsta de 22 ani, iar pe parcursul acestuia
                    împlinești 23 de ani, după data respectivă cursul poate fi
                    continuat doar contracost. Spre exemplu, dacă ai parcurs 30%
                    din curs până la împlinirea vârstei de 23 de ani, restul
                    poate fi continuat doar achitând contravaloarea părții
                    rămase, respectiv 70%.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="5">
                    5. Pot să mă înscriu la un curs subventionat dacă am vârsta
                    de 15 ani?
                  </AccordionHeader>
                  <AccordionBody accordionId="5">
                    Da, dacă ai împlinit vârsta de 15 ani te poți înscrie la
                    cursurile noastre și poți parcurge modulul teoretic însă nu
                    vei putea participa la activitatea de zbor decât după
                    împlinirea vârstei de 16 ani. <br />
                    <strong>
                      Pentru admiterea la cursul de pregătire în vederea
                      obținerii licenței de pilot planor vârsta minimă este de
                      15 ani în anul începerii pregătirii teoretice.
                    </strong>
                    <br />
                    Cursantul poate începe activitatea de zbor, dar va putea
                    efectua zboruri în simplă comandă doar după împlinirea
                    vârstei de 16 ani. De aceea, recomandăm să te înscrii la
                    curs în anul în care vei putea efectua și activitate de
                    zbor, deoarece pauzele mari dintre modulul teoretic și cel
                    practic nu favorizează progresul.
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </Container>
          </Col>
        </Row>
        {existingMember === existingMemberOptions.NEW &&
        touched &&
        birthDate &&
        ageInMonths >= AGE_LIMITS.UNDER &&
        ageInMonths < AGE_LIMITS.MINOR ? (
          <Row>
            <Col md={6}></Col>
            <Col md={6}>
              <Container>
                <p>
                  <i>
                    <strong>Ai sub 18 ani!</strong> Vei avea nevoie de un acord
                    notarial legalizat si semnat de parinti (consimțământ).
                    <br />
                    Fara acest acord nu vei putea participa la cursurile
                    teoretice si practice.
                    <br />
                    Ai{" "}
                    <a
                      href="https://docs.google.com/document/d/1rJ0AFBwvBEiBb9PPDA6c6gaLQQY5G9ok/edit?usp=sharing&ouid=116591481093533213143&rtpof=true&sd=true"
                      target="_blank"
                    >
                      aici
                    </a>{" "}
                    un model de acord notarial.
                    <br />
                    Acest acord trebuie legalizat la un notariat autorizat.
                    <br />
                    Trimite-l in original prin curier pana la data de{" "}
                    {formatDateToDDMMYYYY(agreementLimit)} la Aeroclubul
                    Teritorial unde te-ai inscris.
                    <br />
                    Lista cu adresele Aerocluburilor Teritoriale o gasesti&nbsp;
                    <a
                      href="https://docs.google.com/document/d/1y1Ekt-GBqU1b-5z1QzlWkRX6hdDT4UKS/edit?usp=sharing&ouid=116591481093533213143&rtpof=true&sd=true "
                      target="_blank"
                    >
                      aici
                    </a>
                    .
                  </i>
                </p>
                <h5>Acord notarial</h5>
                <FormGroup>
                  <Input
                    type="file"
                    name="files"
                    accept="application/pdf"
                    className={
                      Boolean(
                        !UploadingFile &&
                          FilesTouched &&
                          (fileError || (!Files && !fileRef))
                      )
                        ? "is-invalid"
                        : ""
                    }
                    styles={getSelectStyles(
                      Boolean(
                        !UploadingFile &&
                          FilesTouched &&
                          (fileError || (!Files && !fileRef))
                      )
                    )}
                    onChange={this.onFilesInput}
                    disabled={UploadingFile}
                  />
                  <FormFeedback>
                    {!UploadingFile &&
                      FilesTouched &&
                      (fileError || (!Files && !fileRef && "Camp obligatoriu"))}
                  </FormFeedback>
                </FormGroup>
              </Container>
            </Col>
          </Row>
        ) : null}
      </>
    );
  }
}

export default ThirdStep;
