export const collaborationTypes = {
  SUBVENTIONAT: "Subventionat",
  CONTRACT: "Contract",
  LOT: "Lot",
};

export const collaborationTypesLabels = {
  [collaborationTypes.SUBVENTIONAT]: "Subventionat",
  [collaborationTypes.CONTRACT]: "Contract",
  [collaborationTypes.LOT]: "Lot",
};

export const existingMemberOptions = {
  NEW: "new",
  EXISTING: "existent",
};

export const taxValues = {
  PAID: "paid",
  NOT_PAID: "not_paid",
  NOT_APPLICABLE: "not_applicable",
  TO_PAY: "to_pay",
};

export const taxLabels = {
  PAID: "Taxa platita",
  NOT_PAID: "Taxa neplatita",
  NOT_APPLICABLE: "Fara taxa",
  TO_PAY: "Taxa trebuie platita in curand",
};

export const taxOptions = [
  {
    value: taxValues.PAID,
    label: taxLabels.PAID,
  },
  {
    value: taxValues.NOT_PAID,
    label: taxLabels.NOT_PAID,
  },
  {
    value: taxValues.NOT_APPLICABLE,
    label: taxLabels.NOT_APPLICABLE,
  },
];

export const statusValues = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const statusLabels = {
  ACTIVE: "Activ",
  INACTIVE: "Inactiv",
};


export const statusOptions = [
  {
    value: statusValues.ACTIVE,
    label: statusLabels.ACTIVE,
  },
  {
    value: statusValues.INACTIVE,
    label: statusLabels.INACTIVE,
  },
];


export const citizenshipOptions = [
  {
    value: false,
    label: "Romana",
  },
  {
    value: true,
    label: "Straina",
  },
];
