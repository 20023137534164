// Packages
import React, { useState, useCallback, useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import { catchHandler, login } from "../utils";
// Styling
import "./Login.css";

const Login = React.memo((props) => {
  const rootPathname = useMemo(() => "/", []);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .post("/api/auth/login", fields)
        .then((res) => {
          const data = res.data;
          login(data);
          history.push(rootPathname);
        })
        .catch((err) => {
          const { email = "", password = "" } = catchHandler(history, err);
          setLoading(false);
          setErrors({ email, password });
        });
    },
    [history, fields, rootPathname]
  );

  const onKeyUp = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onSubmit(event);
      }
    },
    [onSubmit]
  );

  const onCancel = useCallback(
    (event) => {
      event.preventDefault();
      history.push(rootPathname);
    },
    [history, rootPathname]
  );

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, value } = target;
    setFields((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">Login</h2>
            <Form onKeyUp={onKeyUp}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  invalid={Boolean(errors["email"])}
                  value={fields["email"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["email"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="mb-0">
                <Label for="password">Password</Label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="on"
                  invalid={Boolean(errors["password"])}
                  value={fields["password"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["password"]}</FormFeedback>
              </FormGroup>
              <Link className="forgot-password-link" to="/forgot-password">
                Parola uitata?
              </Link>
              <FormGroup className="d-flex justify-content-between mt-5">
                <Button
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Sign in
                </Button>
                <Button outline color="secondary" onClick={onCancel}>
                  Cancel
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default Login;
